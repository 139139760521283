// $primary: #a51616;
$primary: #202020;

/* MAIN COLOR */

$secondary: #007999;

/* SECONDARY COLOR */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;

/* CSS Document */


/*======================================================================================================Template Name: Dunia - Responsive Business/Corporate OnePage TemplateTheme URI: http://lumi-prod.com/templates/duniaAuthor: webCloneAuthor URI: http://lumi-prod.comDescription: Dunia is a fully responsive, creative, clean and modern business and corporate template. It comes with different versions and nilimited color variations. With Dunia template you can amaze your clients. Dunia based on the world's most popular mobile-first and responsive front-end framework - Bootstrap. Version: 1.0Tags: bootstrap3, creative, slideshow, background, jquery, responsive, contact form, corporation, creative, clean, one-page, business, video, youtube, mobile,slider ======================================================================================================/*

-------------------------------------------------------------->>> TABLE OF CONTENTS:----------------------------------------------------------------

for the default color go to colors.css


1.0 General 
2.0 header 
3.0 Sections (about, call to actions, services, testimonials, team, portfolio, pricing, contact ...)
	3.0.1 call to actions
	3.1 about
	3.2 services
	3.3 testimonials
	3.4 portfolio
	3.5 team
	3.6 blog
	3.7 pricing
	3.8 partners
	3.9 contact
4 maps
5 media queries

/*-------------------------------------------------------------->>> 1 General setting & color:----------------------------------------------------------------*/

html,
body {
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Oswald', sans-serif;
}

a,
p,
span {
    font-family: 'Roboto', sans-serif;
    color: #333;
}

.row {
    margin: 20px 0 20px 0;
}

.separator {
    width: 100px;
    position: relative;
}

.separator::after {
    content: '';
    position: absolute;
    top: -4px;
    left: -50px;
    height: 1px;
    width: 200px;
}

section {
    // margin: 20px 0 20px 0;
    padding: 20px 0 20px 0;
}

.btn-body {
    color: rgb(255, 255, 255);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 0;
}

.btn-body:hover {
    background: #fff !important;
}

.btn-home {
    font-family: 'Oswald', sans-serif;
    color: #ddd;
    border-color: #ddd;
    background: none;
    font-size: 16px;
    padding: 10px 30px 10px 30px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    margin-top: 20px;
    border-radius: 0;
}

.btn-home:hover {
    color: #fff;
}

.animated {
    opacity: 0;
}

.btn.focus,
.btn:focus,
.btn:hover {
    color: $wht;
}

.opac {
    opacity: 1;
}

.no-js #loader {
    display: none;
}

.js #loader {
    display: block;
    position: absolute;
    left: 100px;
    top: 0;
}

.se-pre-con {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(../images/Preloader.gif) center no-repeat #fff;
}

h1::first-letter,
h2::first-letter,
.btn-body:hover,
.ftr:hover .col-md-3,
.people:hover h4,
.team-info h4,
.blog .blog-info a,
.contact ul li i,
#zoom-in:hover,
#zoom-out:hover,
.ftr:hover .col-md-9 h4,
.service i,
.services .col-md-3:hover,
.choose i,
.choose .col-md-6:hover h4,
ul.social li:hover i {
    color: #5aa8e7;
}

.separator::after,
.separator::before,
.separator,
.btn-body,
.btn-body:hover,
.btn-home:hover,
.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover,
.about p,
.about .col-md-3,
.about h4,
.team-name i,
.price-table,
.price-table ul li,
.btn-price:hover,
.price-table:hover,
.form-control:focus,
#google-map,
#google-map address,
.service i,
.expertise .bor,
.choose i {
    border-color: #5aa8e7;
}

.separator::after,
.separator::before,
.separator,
.btn-body,
.btn-home:hover,
.ser:hover,
.progress-bar,
.progress span,
.people:hover img,
.team-social ul li:hover,
.price-table h3,
.btn-price:hover,
.discount,
ul.social li,
#zoom-in,
#zoom-out,
.choose::after,
.choose::before,
.services .col-md-3:hover,
.services .col-md-3:hover i,
.choose .col-md-6:hover i {
    background-color: #5aa8e7 !important;
}

ul.social li i {
    color: #fff;
    margin: 0;
}

.navbar-collapse.in {
    background-color: #000;
}


/*-------------------------------------------------------------->>> 2 Header:----------------------------------------------------------------*/

header {
    background: url(../images/background.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    height: 100vh;
    box-shadow: inset 0 0 20em #000;
    width: 100%;
    clear: both;
    position: relative;
}


/*--------------------- Home slider using cloneSlider -----------------------*/

.home-slider {
    background: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


/*===== 4. Navbar Custom ======*/

.main-menu {
    width: 100%;
    height: 25%;
}

.header-content {
    text-align: center;
    width: 100%;
    height: 75%;
}

.navbar-custom {
    padding: 10px 0px;
    width: 100%;
    border-radius: 0px;
    margin-top: 30px;
    z-index: 999;
    margin-bottom: 0px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.sticky-wrapper {
    position: absolute;
    width: 100%;
    z-index: 1000;
}

.navbar-nav {
    margin-top: 9px;
}

.logo {
    padding: 0px 0px;
    max-width: 225px;
    width: 100%;
}

.navbar-custom .navbar-nav li a {
    color: #fff;
    font-size: 15px;
    background-color: transparent !important;
    padding: 5px 10px;
    margin: 0 5px;
    font-weight: 400;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    -webkit-transition: .5s all ease-out;
    transition: .5s all ease-out;
    outline: none;
    @media(max-width: 1024px) {
        letter-spacing: 0em;
        font-size: 1em;
        padding: 5px 5px;
    }
}

.navbar-custom.navbar-nav>li>a:focus,
{
    color: $wht;
    text-decoration: underline;
}

.navbar-custom .navbar-nav>li>a:hover {
    color: $secondary;
    text-decoration: underline;
}

.is-sticky .navbar-custom {
    margin-top: 0px;
    padding: 10px 0px;
    background-color: #222;
    box-shadow: 0 10px 33px rgba(0, 0, 0, .1);
}

.navbar-toggle {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: #ccc;
    background-color: $blk;
    background-image: none;
    border: 1px solid #fff;
}

.navbar-custom .btn-custom {
    margin-top: 8px;
    margin-left: 20px;
}

.navbar-toggle .icon-bar {
    border: 1px solid #fff;
}

header h1,
header h3,
header p {
    color: #ddd;
    text-align: center;
}

p,
span {
    font-size: 15px;
}


/*-------------------------------------------------------------->>> 3 Sections (about, call to actions, services, testimonials, team, portfolio, pricing, contact ...)----------------------------------------------------------------*/


/*-------------------------------------------------------------->>> 3.0.1 call to action----------------------------------------------------------------*/

.fb-folow,
.tw-folow,
.subscribe {
    background: rgba(0, 0, 0, 0.74);
    padding: 100px 10px 100px 10px;
    text-align: center;
    width: 100%;
    color: #fff !important;
    position: relative;
    h2 a {
        font-size: 1em;
        color: #5aa8e7;
    }
}

.fb-folow span,
.tw-folow span,
.subscribe span {
    font-size: 16px;
    color: #fff !important;
}

.fb-folow::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../img/background3.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: 0;
    z-index: -1;
    @media (max-width: 1024px) {
        background-attachment: scroll;
    }
}

.tw-folow::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../img/background4.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: 0;
    z-index: -1;
    @media (max-width: 1024px) {
        background-attachment: scroll;
    }
}

.subscribe::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../img/background5.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: 0;
    z-index: -1;
    @media (max-width: 1024px) {
        background-attachment: scroll;
    }
}


/*-------------------------------------------------------------->>> 3.1 about----------------------------------------------------------------*/

.about .col-md-3 {
    padding: 0 10px 0 10px;
    margin-bottom: 20px;
    text-align: center;
    border-right: thin solid;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.ftr:hover .col-md-3 {
    text-align: center;
    border-right: #ccc thin solid;
}

.ftr .col-md-9 {
    padding: 0 10px 0 10px;
    margin-top: -15px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.about h4 {
    text-align: left;
    border-right: thin solid;
}

.about img {
    max-width: 100%;
    filter: saturate(0.3);
    transition: all 0.4s ease;
}

.about-services img {
    @media (max-width: 1024px) {
        display: block;
        margin: 0px auto;
        width: 100%;
        max-width: 600px;
    }
}

.about-services:hover img {
    max-width: 100%;
    filter: saturate(0.9);
}

.about-services {
    padding: 20px 5px 20px 5px;
}


/*-------------------------------------------------------------->>> 3.2 services----------------------------------------------------------------*/

.services .col-md-3 {
    padding: 2px;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
    max-height: 444px;
}

.service {
    background: #f9f9f9;
    text-align: center;
    padding: 17px 0 20px 0;
    min-height: 244px;
}

.service i {
    padding: 20px;
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background: #fff;
    border: 1px dashed;
    opacity: 1;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.services .col-md-3:hover i {
    color: #fff;
}

.expertise {
    padding-top: 0 !important;
}

.text-justify {
    margin-top: 20px;
}

.progress {
    position: relative;
    overflow: visible;
    border-radius: 0;
}

.progress-bar {
    box-shadow: none;
}

.progress span {
    padding: 1px 3px 1px 3px;
    color: #fff;
    display: block;
    position: absolute;
    top: -30px;
    right: 0;
}

.expertise {
    // padding:40px 0 40px 0;
    position: relative;
    background: #f9f9f9;
    border-top: #e3e3e3 1px solid;
}

.expertise .row {
    margin-bottom: 0 !important;
}

footer img {
    width: 100%;
    display: block;
    margin: 0 auto;
    max-width: 250px;
    margin-bottom: 20px;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}


/* ================= FOOTER =================== */

footer {
    background-color: $blk;
    padding: 50px 0px;
}

footer p {
    color: $footerLinks;
}


/*-------------------------------------------------------------->>> 5 media queries----------------------------------------------------------------*/


/* Custom, iPhone Retina */

@media only screen and (min-width: 320px) {
    h1 {
        font-size: 24px;
    }
    h2 {
        font-size: 18px;
    }
    h3 {
        font-size: 18px;
    }
    h4 {
        font-size: 18px;
    }
    h5 {
        font-size: 14px;
    }
    h6 {
        font-size: 12px;
    }
}


/* Extra Small Devices, Phones */

@media only screen and (min-width: 480px) {}


/* Small Devices, Tablets */

@media only screen and (min-width: 768px) {}


/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) {}


/* Large Devices, Wide Screens */

@media only screen and (min-width: 1200px) {
    h1 {
        font-size: 36px;
    }
    h2 {
        font-size: 30px;
    }
    h3 {
        font-size: 24px;
    }
    h4 {
        font-size: 18px;
    }
    h5 {
        font-size: 14px;
    }
    h6 {
        font-size: 12px;
    }
}

.carousel-inner h1 {
    font-size: 6em;
    @media (max-width: 1024px) {
        font-size: 4em;
    }
    @media (max-width: 767px) {
        font-size: 2em;
    }
}


/* ================== CLONE SIDER =================== */

@charset "utf-8";

/* CSS Document */

.cloneSlider .carousel-inner .item {
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    @media (max-width: 1024px) {
        background-attachment: scroll;
    }
}

.cloneSlider .carousel-inner .item::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1;
}

.cloneSlider .carousel-inner .item,
.cloneSlider .carousel-inner .active.left,
.cloneSlider .carousel-inner .active.right {
    opacity: 0;
}

.cloneSlider .carousel-inner .active,
.cloneSlider .carousel-inner .next.left,
.cloneSlider .carousel-inner .prev.right {
    opacity: 1;
    color: #fff;
}

.cloneSlider .carousel-inner .next,
.cloneSlider .carousel-inner .prev,
.cloneSlider .carousel-inner .active.left,
.cloneSlider .carousel-inner .active.right {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: all 0.5s ease;
}

.cloneSlider .carousel-control {
    z-index: 2;
}

html,
body,
.carousel,
.carousel-inner,
.carousel-inner .item {
    height: 100%;
}

.carousel-control.right,
.carousel-control.left {
    background-image: none;
    filter: none;
    transition: all 0.4s ease;
}

.cloneSlider .carousel-inner .item-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.carousel-indicators li {
    display: inline-block;
    width: 3px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 3px;
    transition: all 0.3s ease;
}

.carousel-indicators .active {
    width: 3px;
    height: 18px;
    background-color: rgb(255, 255, 255);
    margin: 0px;
}

.cloneSlider .right i,
.cloneSlider .left i {
    opacity: 0.3;
    transition: all 0.4s ease;
}

.cloneSlider .right i {
    right: 20px;
}

.cloneSlider .left i {
    left: 20px;
}

.cloneSlider .right:hover i {
    opacity: 0.8;
    right: 40px;
}

.cloneSlider .left:hover i {
    opacity: 0.8;
    left: 40px;
}

.flash {
    display: none;
}

#myModal {
    z-index: 5000;
}

.modal-dialog {
    width: 100% !important;
    max-width: 650px;
    text-align: center;
    margin: 6em auto;
    z-index: 5000;
    margin-top: 150px;
    @media (max-width: 1024px) {
        max-width: 450px;
    }
}

// 	.close {display: none;}
.modal-content {
    color: $blk;
    padding: 5px 10px;
    h2 {
        text-align: center;
    }
}

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    a {
        color: $footerLinks;
        &:hover {
            color: lighten($footerLinks, 10%);
        }
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.lg {
    background: #5aa8e7 !important;
    border-right: 1px solid white;
    a {
        color: black !important;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
    &:hover {
        color: #202020
    }
}